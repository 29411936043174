import type { Middleware } from '@reduxjs/toolkit';
import reduxWebsocket from '../middleware/redux-websocket';

export const prefix = 'TX_WS';

const wsTxMiddleware: Middleware = reduxWebsocket({
  prefix,
  reconnectOnClose: true,
  dateSerializer: (date: Date) => date.toISOString(),
});

export default wsTxMiddleware;
