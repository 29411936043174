import { createSlice } from '@reduxjs/toolkit';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import { parseResponse } from 'src/store/utils';
import { createSession, sendMessage } from 'src/store/shared/trading/actions';
import * as loansActions from 'src/store/loans/actions';
import * as retainersActions from 'src/store/retainers/actions';
import type { JsonTradingSuccess, JsonWebMessage, TradingState } from './types';
import { TradingCommands } from './types';

const allowedCommands = Object.values(TradingCommands);

export const initialState: TradingState = {
  error: null,
  groupList: null,
  groupListStatus: null,
  messageStatus: null,
  messageLog: [],
  sessionCreateStatus: null,
  sessionDetails: null,
};

const Trading = createSlice({
  name: 'trading',
  initialState,
  reducers: {
    resetSession(state) {
      state.messageLog = [];
      state.groupList = null;
      state.sessionDetails = null;
    },
  },
  extraReducers: (builder) => {
    // on WS message

    builder.addCase(createSession.pending, (state) => {
      state.sessionCreateStatus = 'pending';
      state.messageLog = [];
      state.sessionDetails = null;
    });

    builder.addCase(createSession.fulfilled, (state, action) => {
      state.sessionDetails = action.payload;
    });

    builder.addCase(loansActions.listen.pending, (state) => {
      state.groupListStatus = 'pending';
    });

    builder.addCase(retainersActions.listen.pending, (state) => {
      state.groupListStatus = 'pending';
    });

    builder.addCase(sendMessage.pending, (state) => {
      state.messageStatus = 'pending';
    });

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error, errorCommand } = parseResponse(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;

      console.debug(
        `shared/trading/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      if (error) {
        state.error = error;

        switch (errorCommand) {
          default:
        }

        return;
      }

      state.error = null;

      const { command, okCommand } = json;

      if (command === TradingCommands.GroupList) {
        state.groupListStatus = 'success';
        const { groups }: JsonTradingSuccess = json;

        if (groups) {
          state.groupList = groups;
        }
      }

      if (command === TradingCommands.WebMessage) {
        const { session, message, messageID } = json as JsonWebMessage;
        state.messageLog.push({ session, message, messageID });
        state.messageStatus = 'success';
      }

      if (command === 'ok' && okCommand === TradingCommands.CreateSession) {
        // TODO: get session from ok command response. Needed server support. Remove createSession.fulfilled handling after implementation
        // const { session }: JsonTradingCreateSessionSuccess = json;
        state.sessionCreateStatus = 'success';
      }
    });
  },
});

export const tradingActions = {
  ...Trading.actions,
  createSession,
  sendMessage,
};

export default Trading.reducer;
