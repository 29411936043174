import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import type { Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { listenerMiddleware } from 'src/store/middleware/listenerMiddleware';
import { handleWsMessageMiddleware } from 'src/store/middleware/handleWsMessage';
import { sentryReduxEnhancer } from 'src/store/sentry-enhancers';
import { rtkAuthApi } from 'src/store/shared/rtkAuthApi';
import { rtkQueryErrorLogger } from 'src/store/middleware/rtkQueryLogger';
import { rtkApiKey } from 'src/store/apiKeys/actions';
import { rtkColdWalletApi } from 'src/store/cold-wallets/rtkColdWalletsApi';
import { rtkTokenMapApi } from './tokenMap/rtkTokenMapApi';
import loggerMiddleware from './middleware/logger';
import crashMiddleware from './middleware/crash';
import wsLoansMiddleware from './ws-loans/middleware';
import handleWsRequestMiddleware from './middleware/handleWsRequest';
import wsTxMiddleware from 'src/store/trading/middleware';

import themeReducer from './theme/slice';
import adminReducer, { initialState as adminInitialState } from './admin/slice';
import preferencesReducer from './preferences/slice';
import authReducer from './auth/slice';
import loansReducer from './loans/slice';

import wsLoansReducer from './ws-loans/slice';
import reportsReducer, { initialState as reportsInitialState } from './reports/slice';
import signupReducer from './signup/slice';
import rtkQueryErrorReducer, { initialState as rtkQueryErrorInitialState } from './error/slice';

import arbitrageReducer, { initialState as arbitrageInitialState } from './arbitrage/slice';
import apiKeysReducer, { initialState as apiKeysInitialState } from './apiKeys/slice';
import tradingApiKeysReducer, {
  initialState as tradingApiKeysInitialState,
} from './trading/apiKeys/slice';
import retainersReducer from './retainers/slice';

import accountsReducer from './shared/accounts/slice';
import exchangesReducer, { initialState as exchangesInitialState } from './shared/exchanges/slice';
import balancesReducer, { initialState as balancesInitialState } from './shared/balances/slice';
import balanceHistoryReducer, {
  initialState as balanceHistoryInitialState,
} from './shared/balance-history/slice';
import transactionsReducer, {
  initialState as transactionsInitialState,
} from './shared/transactions/slice';
import tradingReducer, { initialState as tradingInitialState } from './shared/trading/slice';
import arbitrageInfoReducer, {
  initialState as arbitrageInfoInitialState,
} from './shared/arbitrage-info/slice';
import tasksReducer, { initialState as tasksInitialState } from './shared/tasks/slice';
import calculatedThresholdsReducer from './shared/threshold/calculation';
import navigationPermissionsReducer, {
  initialState as navigationPermissionsInitialState,
} from 'src/store/navigation/slice';
import { withResetState } from 'src/store/actions';
import wsTxReducer from './trading/slice';

export const rootPersistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [], // things to persist
  // blacklist: [] // things to do not persist
};

export const authPersistConfig = {
  key: 'auth',
  version: 1,
  storage,
  blacklist: ['error', 'resAuth', 'res2FA', 'authDomain'],
};

export const prefPersistConfig = {
  key: 'preferences',
  version: 1,
  storage,
  blacklist: ['error'],
};

export const calculatedThresholdsConfig = {
  key: 'calculatedThresholds',
  version: 1,
  storage,
  blacklist: ['calculatedThresholds'],
};

const reducers = combineReducers({
  [rtkTokenMapApi.reducerPath]: rtkTokenMapApi.reducer,
  [rtkAuthApi.reducerPath]: rtkAuthApi.reducer,
  [rtkColdWalletApi.reducerPath]: rtkColdWalletApi.reducer,
  [rtkApiKey.reducerPath]: rtkApiKey.reducer,
  wsLoans: wsLoansReducer,
  wsTx: wsTxReducer,
  theme: themeReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  preferences: persistReducer(prefPersistConfig, preferencesReducer),
  calculatedThresholds: persistReducer(calculatedThresholdsConfig, calculatedThresholdsReducer),
  signup: signupReducer,
  rtkQueryError: withResetState(rtkQueryErrorReducer, rtkQueryErrorInitialState),

  // shared
  accounts: accountsReducer,
  exchanges: withResetState(exchangesReducer, exchangesInitialState),
  balances: withResetState(balancesReducer, balancesInitialState),
  balanceHistory: withResetState(balanceHistoryReducer, balanceHistoryInitialState),
  transactions: withResetState(transactionsReducer, transactionsInitialState),
  trading: withResetState(tradingReducer, tradingInitialState),
  arbitrageInfo: withResetState(arbitrageInfoReducer, arbitrageInfoInitialState),

  tasks: withResetState(tasksReducer, tasksInitialState),

  // admin reducers
  admin: withResetState(adminReducer, adminInitialState),
  arbitrage: withResetState(arbitrageReducer, arbitrageInitialState),
  apiKeys: withResetState(apiKeysReducer, apiKeysInitialState),
  tradingApiKeys: withResetState(tradingApiKeysReducer, tradingApiKeysInitialState),
  // loans reducers
  loans: loansReducer,

  reports: withResetState(reportsReducer, reportsInitialState),

  // retainers reducers
  retainers: retainersReducer,
  navigationPermissions: withResetState(
    navigationPermissionsReducer,
    navigationPermissionsInitialState,
  ),
});

const persistedReducer = persistReducer(rootPersistConfig, reducers);

export interface ExtraArgument {
  persistor?: Persistor;
}

const extraArgument: ExtraArgument = {};

const getDefaultMiddlewareParams = {
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    // ignoredActions: ['LOANS_WS::OPEN'],
    ignoredActionPaths: ['meta.arg', 'meta.timestamp', 'payload', 'meta.baseQueryMeta'],
  },
  thunk: {
    extraArgument,
  },
};

const middlewares = [
  // order matters
  rtkTokenMapApi.middleware,
  rtkAuthApi.middleware,
  rtkColdWalletApi.middleware,
  rtkApiKey.middleware,
  crashMiddleware,
  loggerMiddleware,
  wsLoansMiddleware,
  wsTxMiddleware,
  handleWsRequestMiddleware,
  handleWsMessageMiddleware,
  // loan and retainer middleware
  listenerMiddleware.middleware,
  rtkQueryErrorLogger,
];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(getDefaultMiddlewareParams).concat(middlewares),
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
});

export function setupStore(preloadedState?: RootState) {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware(getDefaultMiddlewareParams).concat(middlewares),
  });
}

export const persistor = persistStore(store);
extraArgument.persistor = persistor;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];
