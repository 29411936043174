import type { PayloadAction } from '@reduxjs/toolkit';
import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { GenericError } from 'src/store/utils/errors';
import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';

import { startListening } from 'src/store/middleware/listenerMiddleware';
import { balancesActions } from 'src/store/shared/balances/slice';
import { tradingActions } from 'src/store/shared/trading/slice';
import type { RequestStatus } from 'src/store/types';
import { tasksActions } from 'src/store/shared/tasks/slice';
import retainersProjectsReducer, { initialState as ProjectsInitialState } from './projects/slice';
import retainersAdminPanelReducer, {
  initialState as AdminPanelInitialState,
} from './admin-panel/slice';
import retainersBalancesUIReducer from './balance-table-opts/slice';
import retainerTermsReducer, { initialState as TermsInitialState } from './terms/slice';
import retainersKpiReducer from './kpi/slice';
import retainersThresholdReducer from './threshold/slice';
import retainersOverviewReducer, { initialState as OverviewInitialState } from './overview/slice';

import { hideProject, listen } from './actions';
import { RetainerCommands } from './types';
import { withResetState } from 'src/store/actions';

export interface RetainersState {
  error: null | GenericError;

  // Retainers (right-hand) sidebar and header
  listenStatus: RequestStatus;
  isSidebarOpen: boolean;
  targetProject: string;
  targetExchange: string;
  overviewActiveTab: string;

  isNewProjectModalOpen: boolean;
}

const allowedCommands = Object.values(RetainerCommands);

const initialState: RetainersState = {
  error: null,
  // Loans (right-hand) sidebar and header
  listenStatus: null,
  isSidebarOpen: false,
  targetProject: '',
  targetExchange: 'balances',
  overviewActiveTab: '',
  isNewProjectModalOpen: false,
};

const retainersSlice = createSlice({
  name: 'retainers',
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.isSidebarOpen = !state.isSidebarOpen;
    },

    setTargetProject(state, action: PayloadAction<{ projectId: string }>) {
      state.targetProject = action.payload.projectId;
    },

    setTargetExchange(state, action: PayloadAction<{ exchangeId: string }>) {
      state.targetExchange = action.payload.exchangeId || 'balances';
    },

    setTarget(state, action: PayloadAction<{ projectId: string; exchangeId?: string }>) {
      state.listenStatus = null;
      state.targetProject = action.payload.projectId;
      state.targetExchange = action.payload.exchangeId ?? 'balances';
    },

    setOverviewActiveTab(state, action: PayloadAction<string>) {
      state.overviewActiveTab = action.payload;
    },

    openNewProjectModal(state) {
      state.isNewProjectModalOpen = true;
    },

    closeNewProjectModal(state) {
      state.isNewProjectModalOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listen.pending, (state, action) => {
      const { isInitCall, section, projectID } = action.meta.arg;
      if (isInitCall) {
        state.listenStatus = 'pending';

        if (section === 'overview') {
          if (!state.targetProject && projectID) {
            state.targetProject = String(projectID);
          }
        }
      }
    });

    builder.addCase(listen.fulfilled, (state, action) => {
      console.debug('retainers/overview', action);
      const { isInitCall, section } = action.meta.arg;
      if (isInitCall && !['overview'].includes(section)) {
        state.listenStatus = 'success';
      }
    });

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      console.debug(`retainers/processing ${actionOnMessage.toString()}`, action, allowedCommands);

      const { command, okCommand } = json;

      if (error) {
        state.error = error;
        return;
      }

      if (command === 'ok') {
        switch (okCommand) {
          default:
        }
      }
    });
  },
});

export const retainersActions = {
  ...retainersSlice.actions,
  listen,
  hideProject,
};

const balanceTableOptsConfig = {
  key: 'balanceTableOpts',
  version: 1,
  storage,
  blacklist: ['ignoreTokens', 'viewMode'],
};

const persistedRetainersBalancesUIReducer = persistReducer(
  balanceTableOptsConfig,
  retainersBalancesUIReducer,
);

export default combineReducers({
  index: withResetState(retainersSlice.reducer, initialState),
  projects: withResetState(retainersProjectsReducer, ProjectsInitialState),
  balancesUI: persistedRetainersBalancesUIReducer,
  adminPanel: withResetState(retainersAdminPanelReducer, AdminPanelInitialState),
  terms: withResetState(retainerTermsReducer, TermsInitialState),
  kpi: retainersKpiReducer,
  threshold: retainersThresholdReducer,
  overview: withResetState(retainersOverviewReducer, OverviewInitialState),
});

startListening({
  actionCreator: retainersActions.setTarget,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(balancesActions.resetList());
    listenerApi.dispatch(tradingActions.resetSession());
    listenerApi.dispatch(tradingActions.resetSession());
    listenerApi.dispatch(tasksActions.resetFilters());
  },
});
