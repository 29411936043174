import type { PayloadAction } from '@reduxjs/toolkit';
import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import map from 'lodash/map';

import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';

import type { JsonConnection, JsonFailure } from 'src/store/types';

import type {
  JsonAddLoanSuccess,
  JsonLoan,
  JsonLoansSuccess,
  LoansState,
} from 'src/store/loans/types';
import { LoanCommands } from 'src/store/loans/types';
import type { JsonLogSuccess } from 'src/store/shared/types';
import { LoanStatsCommands } from 'src/store/loans/stats/types';
import { startListening } from 'src/store/middleware/listenerMiddleware';
import { balancesActions } from 'src/store/shared/balances/slice';
import { tradingActions } from 'src/store/shared/trading/slice';

import { ApplicationDomain, skyRouter } from 'src/sky-router';
import type { UpdateLoanPayload } from 'src/store/loans/terms/actions';
import type { AddLoanPayload } from 'src/store/loans/terms/types';
import { tasksActions } from 'src/store/shared/tasks/slice';
import loansKpiReducer from 'src/store/loans/kpi/slice';
import { registerErrorMessage } from 'src/store/utils/errors';
import { hideLoan, listen, updateComment } from './actions';

import loansBalancesUIReducer from './balance-table-opts/slice';
import loansStatsReducer, { initialState as LoansStatsInitialState } from './stats/slice';
import loansFundsReducer, {
  loanFundsActions,
  initialState as LoansFundsInitialState,
} from './funds/slice';
import loansProfitReducer, { initialState as LoansProfitInitialState } from './profit/slice';
import loansExchangesReducer, {
  loanExchangesActions,
  initialState as LoansExchangesInitialState,
} from './exchanges/slice';
import loansLiquidityReducer, {
  liquidityActions,
  initialState as LoansLiquidityInitialState,
} from './liquidity/slice';
import loansTradingViewReducer, {
  initialState as LoansTradingViewState,
} from './trading-view/slice';
import loansSummaryReducer, { initialState as LoansSummaryInitialState } from './summary/slice';
import loansStrikePricesReducer, {
  initialState as LoansStrikePricesInitialState,
} from './strike-prices/slice';
import loanTermsReducer, {
  loanTermsActions,
  initialState as LoanTermsInitialState,
} from './terms/slice';
import loanAccountThresholdReducer from './threshold/slice';
import loansOverviewReducer, { initialState as LoansOverviewInitialState } from './overview/slice';
import { EMPTY_ARRAY } from 'src/constants';
import { getDefaultLoanFromList, selectTargetLoan } from 'src/store/loans/selectors';
import some from 'lodash/some';
import { selectJwtPrincipal } from 'src/store/signup/selectors';
import { getAllowedLoans } from 'src/utils/cerbos/loans';
import { resetStore, withResetState } from 'src/store/actions';

const allowedCommands = [...Object.values(LoanCommands), LoanStatsCommands.LoanStats.valueOf()];
const NEW_LOAN_ID_PLACEHOLDER = 9999999999999;

registerErrorMessage(LoanCommands.UpdateLoan, {
  title: 'Loan Terms Update Error',
  getDescription: (json: JsonFailure) =>
    `Sorry, the error has occured on updating loan terms. ${json.message}`,
});

const initialState: LoansState = {
  error: null,
  listenStatus: null,

  isLoadingAllAllowed: 'pending',

  // Loans, data on Login
  list: null,
  allowedLoansList: null,
  companies: EMPTY_ARRAY,

  // Overview, data on Listen/Overview
  comments: [],
  isOverviewLoading: false,

  // Loan ApiKeyModals
  isNewLoanModalOpen: false, // to handle ApiKeyModals window
  resNewLoan: null, // to handle new loan creation status
  resUpdateLoan: null, // to handle loan terms updating status

  // Loan comments and ApiKeyModals
  isNewLoanCommentModalOpen: false,
  resUpdateComment: null, // to handle loan comments updating status

  // Loans (right-hand) sidebar and header
  isSidebarOpen: false,
  targetLoan: '',
  targetExchange: 'overview',

  overviewActiveTab: '',

  termChangesLog: [],
  termsChangesLogLoadingStatus: null,

  // sortkey
  sortKey: 'created_asc',
  searchInput: '',
};

const loansSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.isSidebarOpen = !state.isSidebarOpen;
    },

    setTargetLoan(state, action: PayloadAction<{ loanId: string }>) {
      state.targetLoan = action.payload.loanId;
    },

    setTargetExchange(state, action: PayloadAction<{ exchangeId: string }>) {
      state.targetExchange = action.payload.exchangeId || 'overview';
    },

    setTarget(state, action: PayloadAction<{ loanId: string; exchangeId?: string }>) {
      state.listenStatus = null;
      state.targetLoan = action.payload.loanId;
      state.targetExchange = action.payload.exchangeId ?? 'overview';
    },

    openNewLoanModal(state) {
      state.isNewLoanModalOpen = true;
      state.resNewLoan = null;
    },
    closeNewLoanModal(state, action: { payload: object }) {
      console.debug('closeNewLoanModal action:', action);
      state.isNewLoanModalOpen = false;
    },

    openNewLoanCommentModal(state) {
      state.isNewLoanCommentModalOpen = true;
      state.resUpdateComment = null;
    },
    closeNewLoanCommentModal(state, action: { payload: object }) {
      console.debug('closeNewLoanCommentModal action:', action);
      state.isNewLoanCommentModalOpen = false;
    },

    setOverviewActiveTab(state, action: { payload: string }) {
      state.overviewActiveTab = action.payload;
    },

    updateNewLoanId(state, action: { payload: { loanId: number } }) {
      const { loanId } = action.payload;
      state.list?.forEach((loan) => {
        if (loan.id === NEW_LOAN_ID_PLACEHOLDER) {
          loan.id = loanId;
        }
      });
    },
    setSearchInput(state, action: PayloadAction<string>) {
      state.searchInput = action.payload;
    },
    setSortKey(state, action: PayloadAction<string>) {
      state.sortKey = action.payload;
    },
    setAllowedLoansList(state, action: { payload: JsonLoan[] }) {
      state.allowedLoansList = action.payload;
      state.isLoadingAllAllowed = 'success';
    },
  },
  extraReducers: (builder) => {
    // addLoan

    builder.addCase(loanTermsActions.addLoan.pending, (state, action) => {
      console.log('loans/addLoan.pending:', action);
      state.resNewLoan = 'pending';
    });

    builder.addCase(loanTermsActions.addLoan.rejected, (state, action) => {
      console.log('loans/addLoan.rejected:', action);
      state.resNewLoan = 'error';
    });

    builder.addCase(loanTermsActions.addLoan.fulfilled, (state, action) => {
      console.debug('loans/addLoan.fulfilled', action.payload);
      // state.isNewLoanSubmitting = false;

      const addedData: AddLoanPayload = action.payload;

      state.list?.push({
        id: NEW_LOAN_ID_PLACEHOLDER,
        name: addedData.name,
        exchanges: map(addedData.exchanges, (exchangeId) => ({
          id: exchangeId,
          bots: 0,
        })),
      });
    });

    // updateLoan
    builder.addCase(loanTermsActions.updateLoan.fulfilled, (state, action) => {
      console.debug('loans/updateLoan.fulfilled', action.payload);
      const updatedData: UpdateLoanPayload = action.payload;
      if (!updatedData) {
        return;
      }
      state.list = map(state.list, (loan) => {
        if (loan.id === updatedData.loanID) {
          if (updatedData.name) {
            loan.name = updatedData.name;
          }
          if (updatedData.exchanges) {
            loan.exchanges = map(updatedData.exchanges, (exchangeId) => ({
              id: exchangeId,
              bots: 0,
            }));
          }
          return loan;
        }

        return loan;
      });
    });

    // hideLoan

    builder.addCase(hideLoan.pending, (state, action) => {
      console.log('loans/hideLoan.pending:', action);
      state.resUpdateLoan = 'pending';
    });

    // updateComment

    builder.addCase(updateComment, (state, action) => {
      console.log(`${updateComment.toString()}:`, action);
      state.resUpdateComment = 'pending';
    });

    // listen

    builder.addCase(listen.pending, (state, action) => {
      const { isInitCall, section, loanID } = action.meta.arg;
      if (isInitCall) {
        state.listenStatus = 'pending';
        state.isOverviewLoading = section === 'overview' || section === 'terms';
        state.termsChangesLogLoadingStatus = 'pending';
        if (!state.targetLoan && loanID) {
          state.targetLoan = String(loanID);
        }
      }
    });

    builder.addCase(listen.fulfilled, (state, action) => {
      const { isInitCall } = action.meta.arg;
      if (isInitCall) {
        state.listenStatus = 'success';
      }
    });

    // on WS message

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error, errorCommand } = parseResponse(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;

      console.debug(`loans/processing ${actionOnMessage.toString()}`, action, allowedCommands);

      const { command, okCommand } = json;

      if (error) {
        state.error = error;

        switch (errorCommand) {
          case LoanCommands.HideLoan:
            state.resUpdateLoan = 'error';
            break;
          case LoanCommands.AddLoan:
            state.resNewLoan = 'error';
            break;
          default:
        }

        return;
      }

      if (command === 'ok' && okCommand === LoanCommands.HideLoan) {
        const { result: loanID }: JsonAddLoanSuccess = json;
        state.targetLoan = '';
        state.resUpdateLoan = 'success';
        console.debug('HideLoan loanID:', loanID);
        return;
      }

      if (command === 'ok' && okCommand === LoanCommands.AddLoan) {
        const { result: loanID }: JsonAddLoanSuccess = json;
        state.resNewLoan = 'success';
        state.isNewLoanModalOpen = false;
        console.debug('AddLoan loanID:', loanID);
        return;
      }

      if (command === 'ok' && okCommand === LoanCommands.UpdateComment) {
        state.resUpdateComment = 'success';
        state.isNewLoanCommentModalOpen = false;
        console.debug('UpdateComment', json);
        return;
      }

      if (command === LoanCommands.Loans) {
        const { list, companies }: JsonLoansSuccess = json;

        state.list = list;
        state.companies = companies;
        state.error = null;
        return;
      }

      // Overview
      if (command === LoanStatsCommands.LoanStats) {
        state.isOverviewLoading = false;
        return;
      }

      // Exchange details

      if (command === 'Log') {
        const { list } = json as JsonLogSuccess;
        state.termChangesLog = list;
        state.termsChangesLogLoadingStatus = 'success';
      }
    });

    builder.addCase(resetStore, (_, action) => {
      console.log('~ action', action);
      return initialState;
    });
  },
});

export const loansActions = {
  ...loansSlice.actions,
  updateComment,
  hideLoan,
  listen,
};

// export const { toggleSidebar, openNewLoanModal, closeNewLoanModal } = loansSlice.actions

const balanceTableOptsConfig = {
  key: 'balanceTableOpts',
  version: 1,
  storage,
  blacklist: ['ignoreTokens', 'viewMode'],
};

const persistedLoansBalancesUIReducer = persistReducer(
  balanceTableOptsConfig,
  loansBalancesUIReducer,
);

export default combineReducers({
  index: withResetState(loansSlice.reducer, initialState),
  balancesUI: persistedLoansBalancesUIReducer,
  stats: withResetState(loansStatsReducer, LoansStatsInitialState),
  funds: withResetState(loansFundsReducer, LoansFundsInitialState),
  profit: withResetState(loansProfitReducer, LoansProfitInitialState),
  exchanges: withResetState(loansExchangesReducer, LoansExchangesInitialState),
  liquidity: withResetState(loansLiquidityReducer, LoansLiquidityInitialState),
  tradingView: withResetState(loansTradingViewReducer, LoansTradingViewState),
  strikePrices: withResetState(loansStrikePricesReducer, LoansStrikePricesInitialState),
  kpi: loansKpiReducer,
  summary: withResetState(loansSummaryReducer, LoansSummaryInitialState),
  terms: withResetState(loanTermsReducer, LoanTermsInitialState),
  threshold: loanAccountThresholdReducer,
  overview: withResetState(loansOverviewReducer, LoansOverviewInitialState),
});

// on switch the target loan
// it should reset balances list
startListening({
  actionCreator: loansActions.setTarget,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(loanExchangesActions.resetState());
    listenerApi.dispatch(loanExchangesActions.loadCache(action.payload));
    listenerApi.dispatch(liquidityActions.loadCache(action.payload));
    listenerApi.dispatch(balancesActions.resetList());
    listenerApi.dispatch(tradingActions.resetSession());
    listenerApi.dispatch(tasksActions.resetFilters());
  },
});

// on new loan or loan update
// it should update committed funds and strike prices
// TODO: Create actions for update state of data list in new loan
startListening({
  actionCreator: actionOnMessage,
  effect: async (action, listenerApi) => {
    const json = JSON.parse((action.payload as JsonConnection).message) as JsonAddLoanSuccess;
    if (json.command === 'ok' && json.okCommand === LoanCommands.AddLoan.toString()) {
      const { result: loanID } = json;
      const state = listenerApi.getState();

      const {
        loans: {
          funds: { initialCommittedFunds },
          // index: { formStrikePrices, formKpi },
        },
      } = state;

      listenerApi.dispatch(loansActions.updateNewLoanId({ loanId: +loanID }));

      if (initialCommittedFunds) {
        listenerApi.dispatch(
          loanFundsActions.addCommittedFunds({
            loanID,
            amount: initialCommittedFunds,
            token: 'USD',
          }),
        );
      }

      // redirect to the loan terms
      await skyRouter.router?.navigate(`/loans/terms/${loanID}`);
    }
  },
});

startListening({
  actionCreator: actionOnMessage,
  effect: async (action, listenerApi) => {
    const json = JSON.parse((action.payload as JsonConnection).message) as JsonLoansSuccess;
    if (json.command === LoanCommands.Loans) {
      const state = listenerApi.getState();
      const { list } = json;
      const currentTargetId = selectTargetLoan(state);
      const principal = selectJwtPrincipal(state);
      const allowedLoans = await getAllowedLoans(list, principal);
      listenerApi.dispatch(loansActions.setAllowedLoansList(allowedLoans));

      if (!currentTargetId && list?.length) {
        const defaultLoan = getDefaultLoanFromList(list);
        const [, , , urlLoanId] = skyRouter.getPathname().split('/');
        const loanId = urlLoanId ? urlLoanId : String(defaultLoan?.id ?? '');
        const isExist = some(list, { id: Number(loanId) });
        const domain = skyRouter.getDomain();
        if (!isExist && domain === ApplicationDomain.Loans) {
          void skyRouter.router?.navigate('/loans/not-found');
          return;
        }
        listenerApi.dispatch(loansActions.setTargetLoan({ loanId }));
      }
    }
  },
});
