import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  actionTxConnect,
  actionTxDisconnect,
  actionTxOnClose,
  actionTxOnClosed,
  actionTxOnError,
  actionTxOnMessage,
  actionTxOnOpen,
  actionTxOnReconnectBegin,
  actionTxSend,
} from './actions';
import { startListening } from 'src/store/middleware/listenerMiddleware';
import { signUpApi } from 'src/store/signup/actions';
import { ensureAccessToken } from 'src/store/shared/rtkAuthApi/refresh-auth';
import { selectAccessToken } from 'src/store/signup/selectors';

export interface WsTxState {
  status: 'idle' | 'connecting' | 'disconnecting' | 'connected';
  error: object | null;
  isReconnecting: boolean;
}

const initialState: WsTxState = {
  status: 'idle',
  error: null,
  isReconnecting: false,
};

const wsTxSlice = createSlice({
  name: 'wsTx',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actionTxConnect, (state, action) => {
      console.log(`${actionTxConnect.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionTxConnect.toString()}`, action);
      if (state.status === 'connected') {
        state.isReconnecting = true;
      }
      state.status = 'connecting';
    });

    builder.addCase(actionTxOnReconnectBegin, (state, action) => {
      console.log(`${actionTxOnReconnectBegin.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionTxOnReconnectBegin.toString()}`, action);
      if (state.status === 'connected' || state.status === 'idle') {
        state.isReconnecting = true;
      }
      state.status = 'connecting';
    });

    builder.addCase(actionTxDisconnect, (state, action) => {
      console.log(`${actionTxDisconnect.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionTxDisconnect.toString()}`, action);
      state.status = 'disconnecting';
    });

    builder.addCase(actionTxOnOpen, (state, action) => {
      console.log(`${actionTxOnOpen.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionTxOnOpen.toString()}`, action);
      state.status = 'connected';
      state.isReconnecting = false;
      state.error = null;
    });

    builder.addCase(actionTxOnClose, (state, action) => {
      console.log(`${actionTxOnClose.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionTxOnClose.toString()}`, action);
      state.status = 'idle';
      // state.error = null;
    });

    builder.addCase(actionTxOnClosed, (state, action) => {
      console.log(`${actionTxOnClosed.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionTxOnClosed.toString()}`, action);
      state.status = 'idle';
      // state.error = null;
    });

    builder.addCase(actionTxSend, (state, action) => {
      console.log(`${actionTxSend.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionTxSend.toString()}`, action);
    });

    builder.addCase(actionTxOnMessage, (state, action) => {
      console.log(`${actionTxOnMessage.toString()} ${action.payload.message}`);
      try {
        console.debug(`${actionTxOnMessage.toString()}`, JSON.parse(action.payload.message));
      } catch (e) {
        console.error('Failed to parse message', e);
      }
    });

    builder.addCase(actionTxOnError, (state, action) => {
      console.error(`${actionTxOnError.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionTxOnError.toString()}`, action);
      state.status = 'idle';
      state.error = action.payload;
    });
  },
});

startListening({
  matcher: isAnyOf(actionTxOnOpen, signUpApi.endpoints.postAuthenticate2FA.matchFulfilled),
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    const accessToken = selectAccessToken(state);
    await ensureAccessToken(listenerApi, accessToken);
  },
});

startListening({
  actionCreator: actionTxSend,
  effect: async (_, api) => {
    const accessToken = selectAccessToken(api.getState());
    await ensureAccessToken(api, accessToken);
  },
});

export const wsTxActions = wsTxSlice.actions;
export default wsTxSlice.reducer;
