import { createSlice } from '@reduxjs/toolkit';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import { parseResponse } from 'src/store/utils';
import type { JsonAddLoanSuccess } from 'src/store/loans/types';
import { LoanCommands } from 'src/store/loans/types';
import defaults from 'lodash/defaults';
import isNil from 'lodash/isNil';
import type { JsonLoanTermsSuccess, LoanTermsState } from './types';
import { LoanTermsCommands } from './types';
import type { UpdateLoanPayload } from './actions';
import { addLoan, updateLoan } from './actions';

export const initialState: LoanTermsState = {
  id: undefined,
  details: undefined,
  requestStatus: null,
  error: null,
  isLoanEditing: false, // to handle loan editing mode
  loanTermsEditState: {
    company: false,
    name: false,
    started: false,
    ended: false,
    'amounts.baseAmount': false,
    'amounts.quoteAmount': false,
    strikePrice: false,
    'paybackAmounts.baseAmount': false,
    'paybackAmounts.quoteAmount': false,
    'paybackAmounts.residualAmount': false,
    exchanges: false,
    pairs: false,
    kpi: false,
    tokens: false,
  },
};

const allowedCommands = Object.values(LoanTermsCommands);

const loanTermsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    setFieldEditState(state, action: { payload: { field: string; value: boolean } }) {
      state.loanTermsEditState = {
        ...state.loanTermsEditState,
        [action.payload.field]: action.payload.value,
      };
    },
    toggleLoanEditing(state, action: { payload: { value?: boolean } }) {
      console.debug('toggleLoanEdit action:', action);
      state.isLoanEditing = !isNil(action.payload.value)
        ? action.payload.value
        : !state.isLoanEditing;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateLoan.pending, (state, action) => {
      console.log('loans/updateLoan.pending:', action);
      state.requestStatus = 'pending';
    });

    builder.addCase(updateLoan.rejected, (state, action) => {
      console.log('loans/updateLoan.rejected:', action);
      state.requestStatus = 'error';
    });

    // updateLoan
    builder.addCase(updateLoan.fulfilled, (state, action) => {
      console.debug('loans/updateLoan.fulfilled', action.payload);
      const updatedData: UpdateLoanPayload = action.payload;
      if (!updatedData || !state.details) {
        return;
      }

      state.details = {
        ...defaults(updatedData, state.details),
        tokens: updatedData.tokens ?? state.details.tokens ?? [],
      };
    });

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error, errorCommand } = parseResponse(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;

      console.debug(`loans/processing ${actionOnMessage.toString()}`, action, allowedCommands);

      const { command, okCommand } = json;

      if (error) {
        state.error = error;

        switch (errorCommand) {
          case LoanCommands.UpdateLoan:
            state.requestStatus = 'error';
            break;
          default:
        }
      }

      if (command === LoanTermsCommands.Terms) {
        const { details, id }: JsonLoanTermsSuccess = json;
        state.details = {
          ...details,
          tokens: details.tokens?.split(',') || [],
        };
        state.id = id;
        state.requestStatus = 'success';
      }

      if (command === 'ok' && okCommand === LoanCommands.UpdateLoan) {
        const { result: loanID }: JsonAddLoanSuccess = json;
        state.requestStatus = 'success';
        console.debug('UpdateLoan loanID:', loanID, json);
      }
    });
  },
});

export const loanTermsActions = {
  ...loanTermsSlice.actions,
  updateLoan,
  addLoan,
};

export default loanTermsSlice.reducer;
