import type { RootState } from 'src/store';
import { createSelector } from '@reduxjs/toolkit';
import { getTokenPayload } from 'src/store/shared/rtkAuthApi/helpers';
import { getPrincipal } from 'src/sky-ui-lib/SkyAuthz/getPrincipal';

export const selectSignupEmail = (state: RootState) => state.signup.email;
export const selectSignupFullName = (state: RootState) => state.signup.fullName;
export const selectSignupCompany = (state: RootState) => state.signup.company;
export const selectSignupReferredBy = (state: RootState) => state.signup.referredBy;
export const selectSignupError = (state: RootState) => state.signup.error;
export const selectSignupLoading = (state: RootState) => state.signup.isLoading;

export const selectAccessToken = (state: RootState) => state.signup.accessToken;
export const selectIsRecovery = (state: RootState) => state.signup.isRecovery;

export const selectIsMfaEnabled = createSelector(
  selectAccessToken,
  (accessToken) => getTokenPayload(accessToken).isMfaEnabled ?? false,
);

export const selectIsMfaAuthenticated = createSelector(
  selectAccessToken,
  (accessToken) => getTokenPayload(accessToken).isMfaAuthenticated ?? false,
);
export const selectIsEmailConfirmed = createSelector(
  selectAccessToken,
  (accessToken) => getTokenPayload(accessToken).isEmailConfirmed ?? false,
);

export const selectTokenRoles = createSelector(
  selectAccessToken,
  (accessToken) => getTokenPayload(accessToken).roles ?? [],
);

export const selectAccessTokenPayload = createSelector(selectAccessToken, (accessToken) =>
  getTokenPayload(accessToken),
);

export const selectJwtPrincipal = createSelector(selectAccessTokenPayload, getPrincipal);
