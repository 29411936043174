import { createSelector } from '@reduxjs/toolkit';
import { getHistoryPrices, getPrices } from 'src/store/utils/balances';
import dayjs from 'src/utils/formatting/dates';
import { isComparisonDateNow } from 'src/store/shared/balance-history/helpers';
import type { BalanceHistoryState, JsonBalanceHistoryItem } from './types';
import { selectBalancesSelection } from 'src/store/shared/balances/selectors';

interface Store {
  balanceHistory: BalanceHistoryState;
}

export const selectBalanceHistory = (store: Store) => store.balanceHistory.list;

export const selectBalancePriceHistory = (store: Store) => store.balanceHistory.priceHistory;

const selectBalancePrices = (store: Store) => store.balanceHistory.prices;

export const selectIsBalanceHistoryLoading = (store: Store) =>
  store.balanceHistory.requestStatus === 'pending';

export const selectComparisonStartDate = (store: Store) => store.balanceHistory.startDate;
export const selectComparisonEndDate = (store: Store) => store.balanceHistory.endDate;

export const selectComparisonStartTime = (store: Store) => store.balanceHistory.startTime;
export const selectComparisonEndTime = (store: Store) => store.balanceHistory.endTime;

export const selectIsInitialBalanceModalOpen = (store: Store) =>
  store.balanceHistory.isInitialBalanceModalOpen;

export const selsectSetInitialBalanceInitialValues = (store: Store) =>
  store.balanceHistory.formSetInitialBalance;

export const selectIsInitialBalanceSubmitting = (store: Store) =>
  store.balanceHistory.resSetInitialBalance === 'pending';

export const selectIsInitialBalanceSubmitted = (store: Store) =>
  store.balanceHistory.resSetInitialBalance === 'success';

// get the average price through all the exchanges
// for base token for every day
export const selectPriceHistory = createSelector(
  selectBalanceHistory,
  selectComparisonEndDate,
  selectBalancesSelection,
  selectBalancePriceHistory,
  getHistoryPrices,
);

export const selectPrices = createSelector(selectBalancePrices, getPrices);

const selectComparisonTimes = (
  comparisonDate: string | undefined,
  history: JsonBalanceHistoryItem[],
) => {
  if (!comparisonDate || !history) {
    return [];
  }
  const isTodayCompare = comparisonDate && isComparisonDateNow(comparisonDate);
  return (
    history
      // add 1 minute correction for end of the day snapshot to match 00:00 time
      .map((item) => {
        const dayjsItem = dayjs.utc(item.date);
        const isTodayDate = dayjsItem.isSame(dayjs.utc(), 'date');
        if (isTodayDate) {
          return dayjsItem;
        }
        if (dayjsItem.format('HH:mm') === '23:59') {
          return dayjsItem.add(1, 'minute');
        }
        return dayjsItem;
      })
      // keep items that are related only for selected comparison date
      .filter((dateDayjs) => dateDayjs.isSame(dayjs.utc(comparisonDate), 'date'))
      .sort((a, z) => (a.isBefore(z) ? -1 : 1))
      // map values to HH:mm string and keep only unique values
      .reduce((acc, item) => {
        const hhmmTime = item.format('HH:mm');
        if (acc.includes(hhmmTime)) {
          return acc;
        }
        acc.push(hhmmTime);
        return acc;
      }, [] as string[])
      // map values to options with labels. Last time for today comparison date is Current
      .map((hhmmTime, idx, arr) => {
        if (isTodayCompare && idx === arr.length - 1) {
          return {
            label: 'Current',
            value: 'current',
          };
        }

        return {
          label: hhmmTime === '00:00' ? 'Midnight (UTC)' : hhmmTime,
          value: hhmmTime,
        };
      })
  );
};

export const selectBalanceHistoryEndSnapshotTimes = createSelector(
  selectComparisonEndDate,
  selectBalanceHistory,
  selectComparisonTimes,
);

export const selectBalanceHistoryStartSnapshotTimes = createSelector(
  selectComparisonStartDate,
  selectBalanceHistory,
  selectComparisonTimes,
);

export const selectBalanceHistoryCurrentDate = createSelector(
  selectBalanceHistory,
  (balanceHistory: JsonBalanceHistoryItem[]) => {
    if (!balanceHistory || balanceHistory.length === 0) {
      return null;
    }

    const latestSnapshot = balanceHistory.reduce((latest, current) => {
      return dayjs(current.date).isAfter(dayjs(latest.date)) ? current : latest;
    });

    return latestSnapshot.date;
  },
);
