import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { actionOnMessage } from 'src/store/ws-loans/actions';
import { parseResponse } from 'src/store/utils';

import type { BalancesState, JsonBalance, JsonBalancesSuccess } from './types';
import { BalanceCommands } from './types';

import { addBalance, createBalancesSnapshot, createSnapshots } from './actions';
import { registerErrorMessage } from 'src/store/utils/errors';

const allowedCommands = Object.values(BalanceCommands);

registerErrorMessage(BalanceCommands.CloseSmallTokens, { title: 'Failed to close small tokens' });
registerErrorMessage(BalanceCommands.AddBalance, { title: 'Failed save balances snapshot' });

export const initialState: BalancesState = {
  error: null,
  requestStatus: null,

  // Loans balances, data on Balances
  list: [],
  started: '',
  ended: '',

  selection: [],

  createBalanceSnapshotStatus: null,
  createWalletSnapshotStatus: null,

  addBalanceStatus: null,

  supportedTokens: [],
};

const Balances = createSlice({
  name: 'balances',
  initialState,
  reducers: {
    setSelection(state, action: PayloadAction<JsonBalance['accountID'][]>) {
      state.selection = action.payload;
    },
    resetList(state) {
      state.list = [];
      state.selection = [];
      //if we don't reset the status and switch to Loans, we see notifications about success snapshots
      state.createBalanceSnapshotStatus = null;
      state.createWalletSnapshotStatus = null;
      state.addBalanceStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createBalancesSnapshot, (state) => {
      state.createBalanceSnapshotStatus = 'pending';
      state.addBalanceStatus = null;
    });

    builder.addCase(createSnapshots.pending, (state) => {
      state.createWalletSnapshotStatus = 'pending';
      state.addBalanceStatus = null;
    });

    builder.addCase(createSnapshots.fulfilled, (state) => {
      state.createWalletSnapshotStatus = 'success';
    });

    builder.addCase(createSnapshots.rejected, (state) => {
      state.createWalletSnapshotStatus = 'error';
    });

    builder.addCase(addBalance.pending, (state) => {
      state.addBalanceStatus = 'pending';
      state.createBalanceSnapshotStatus = null;
      state.createWalletSnapshotStatus = null;
    });

    // on WS message

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error, errorCommand } = parseResponse(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;

      console.debug(
        `shared/balances/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      if (error) {
        state.error = error;

        switch (errorCommand) {
          case BalanceCommands.AddBalance:
            // AddBalance use the same command as CreateSnapshot so we need to check if it's AddBalance
            if (state.createBalanceSnapshotStatus === 'pending') {
              state.createBalanceSnapshotStatus = 'error';
            }
            if (state.addBalanceStatus === 'pending') {
              state.addBalanceStatus = 'error';
            }
            break;
          default:
        }

        return;
      }

      state.error = null;

      const { command, okCommand } = json;

      if (command === BalanceCommands.Balances) {
        const { balances, supportedTokens, started, ended }: JsonBalancesSuccess = json;
        if (balances) {
          state.list = balances;
        }

        if (supportedTokens) {
          state.supportedTokens = supportedTokens;
        }

        if (started) {
          state.started = started;
        }

        if (ended) {
          state.ended = ended;
        }

        return;
      }

      if (command === 'ok' && okCommand === BalanceCommands.AddBalance) {
        // AddBalance use the same command as CreateSnapshot so we need to check if it's AddBalance
        if (state.createBalanceSnapshotStatus === 'pending') {
          state.createBalanceSnapshotStatus = 'success';
        }
        if (state.addBalanceStatus === 'pending') {
          state.addBalanceStatus = 'success';
        }
      }
    });
  },
});

export const balancesActions = {
  ...Balances.actions,
  addBalance,
  createBalancesSnapshot,
  createSnapshots,
};
// export const { toggleSidebar, openNewLoanModal, closeNewLoanModal } = Balances.actions

export default Balances.reducer;
