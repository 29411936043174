import { createSlice } from '@reduxjs/toolkit';

import type { GenericError } from 'src/store/utils/errors';
import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import type { RequestStatus } from 'src/store/types';

// import { listen } from '../actions';
import type { BindUnbindAccountResult, JsonBindAccountPayload } from './types';
import { AdminPanelCommands } from './types';
import { bindAccount, unbindAccount } from 'src/store/retainers/admin-panel/actions';

export interface JsonProjectAdmin {
  id: number;
  name: string;
  accounts: number[];
}

export interface JsonUserAccount {
  id: number;
  name: string;
}

export interface RetainersAdminPanelState {
  requestStatus: RequestStatus;
  error: null | GenericError;
  resBindAccount: RequestStatus;
  resUnbindAccount: RequestStatus;
  resBindAccountErrorMessage: string | null;
  projects: JsonProjectAdmin[] | null;
  userAccounts: JsonUserAccount[];
}

const allowedCommands = Object.values(AdminPanelCommands);

function safeParseJSON<T>(jsonString: string): T | null {
  try {
    return JSON.parse(jsonString) as T;
  } catch (error) {
    console.error('Failed to parse JSON:', error);
    return null;
  }
}

export const initialState: RetainersAdminPanelState = {
  requestStatus: null,
  error: null,
  resBindAccount: null,
  resUnbindAccount: null,
  resBindAccountErrorMessage: null,
  projects: null,
  userAccounts: [],
};

const retainersAdminPanelSlice = createSlice({
  name: 'adminPanel', // retainers.adminPanel
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // on WS message

    builder.addCase(bindAccount, (state) => {
      state.resBindAccount = 'pending';
      state.resBindAccountErrorMessage = null;
    });

    builder.addCase(unbindAccount, (state) => {
      state.resUnbindAccount = 'pending';
    });

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error, errorCommand } = parseResponse<JsonBindAccountPayload>(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;

      console.debug(
        `retainers/adminPanel/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      const { command, okCommand, message } = json;

      if (error) {
        state.error = error;

        switch (errorCommand) {
          case AdminPanelCommands.AdminPanel:
            state.requestStatus = 'error';
            break;
          case AdminPanelCommands.BindAccount:
            state.resBindAccount = 'error';
            state.resBindAccountErrorMessage = message;
            break;
          default:
        }
      }

      if (command === AdminPanelCommands.AdminPanel) {
        state.userAccounts = json.accounts;
        state.projects = json.projects;
        state.requestStatus = 'success';
        return;
      }
      if (command === 'ok' && okCommand === AdminPanelCommands.BindAccount) {
        state.error = null;
        const result = safeParseJSON<BindUnbindAccountResult>(json.result);
        if (result) {
          const { accountID, projectID, direction } = result;
          const project = state.projects?.find((p) => p.id === projectID);
          if (project) {
            if (direction === 1) {
              project.accounts.push(accountID);
              state.resBindAccount = 'success';
            } else if (direction === 0) {
              project.accounts = project.accounts.filter((id) => id !== accountID);
              state.resUnbindAccount = 'success';
            }
          }
        } else {
          state.resBindAccount = 'error';
          state.resBindAccountErrorMessage = message;
        }
      }

      // if (command === 'ok') {
      //   switch (okCommand) {
      //     // case 'Projects':
      //     //   state.list = json.list;
      //     //   break;
      //
      //     default:
      //   }
      // }
    });
  },
});

export const retainersProjectsActions = { ...retainersAdminPanelSlice.actions };
// export const { toggleSidebar } = retainersAdminPanelSlice.actions;

export default retainersAdminPanelSlice.reducer;
