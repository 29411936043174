import { createSlice } from '@reduxjs/toolkit';

import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';

import { LoanProfitCommands } from './types';
import { listen } from '../actions';

import type { JsonLoanProfitSuccess, LoanProfitState } from './types';

const allowedCommands = Object.values(LoanProfitCommands);

export const initialState: LoanProfitState = {
  requestStatus: null,
  error: null,

  // LoanProfit, data on Listen
  dlaProfit: {},
  dlaTasks: [],
  dlaFee: {},
  ppProfit: {},
  ppTasks: [],
  ppFee: {},
};

const loanProfitSlice = createSlice({
  name: 'profit', // loans.profit
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // listen

    builder.addCase(listen.pending, (state, action) => {
      const { isInitCall, section } = action.meta.arg;
      if (isInitCall && section === 'overview') {
        state.requestStatus = 'pending';
      }
    });

    // on WS message

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      console.debug(
        `loans/profit/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      const { command, okCommand } = json;

      if (error) {
        state.error = error;
        return;
      }

      if (command === LoanProfitCommands.LoanProfit) {
        const { dlaProfit, dlaFee, ppProfit, ppFee, dlaTasks, ppTasks }: JsonLoanProfitSuccess =
          json;

        state.dlaProfit = dlaProfit;
        state.dlaFee = dlaFee;
        state.ppProfit = ppProfit;
        state.ppFee = ppFee;
        state.ppTasks = ppTasks;
        state.dlaTasks = dlaTasks;
        state.requestStatus = 'success';
        return;
      }

      if (command === 'ok' && okCommand) {
        // state.requestStatus = 'success'
      }
    });
  },
});

export const loanProfitActions = { ...loanProfitSlice.actions };
// export const { toggleSidebar } = loanProfitSlice.actions

export default loanProfitSlice.reducer;
