import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';

export type { Dayjs } from 'dayjs';

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(isToday);

type DatePeriod = 'year' | 'month';

export interface DatePreset {
  label: string;
  value: [Dayjs, Dayjs];
}

export enum DateFormatDayjs {
  Date = "MMM D, 'YY",
  DateTime = 'DD.MM.YYYY HH:mm',
}
export const dateFormat = "MMM D, 'YY";
export const dateTimeFormat = 'DD.MM.YYYY HH:mm';
export const dateTimeFormatAlt = "MMM D, 'YY, HH:mm";

const periodFormatMap: Record<DatePeriod, string> = {
  year: 'YYYY',
  month: 'MMMM',
};

export const formatDate = (date: string) => dayjs(date).format(dateFormat);
export const formatTimeDate = (date: string) => dayjs(date).format(dateTimeFormatAlt);

interface GetBeforePresetsParams {
  count: number;
  period: DatePeriod;
  monthFormat?: string;
  startDate?: Dayjs;
}

export const getBeforePresets = ({
  count,
  period,
  monthFormat,
  startDate,
}: GetBeforePresetsParams): DatePreset[] => {
  const presets: DatePreset[] = [];
  const projectStart = startDate ? dayjs.utc(startDate) : undefined;

  for (let i = 0; i < count; i += 1) {
    const date = dayjs().utc().startOf(period).subtract(i, period);
    let periodStart = date.startOf(period);
    const periodEnd = date.endOf(period);

    if (projectStart && periodEnd.isBefore(projectStart)) {
      continue;
    }

    if (projectStart && periodStart.isBefore(projectStart)) {
      periodStart = projectStart;
    }

    let label = date.format(
      period === 'month' && monthFormat ? monthFormat : periodFormatMap[period],
    );

    if (period === 'year' && label === '2024') {
      label = 'YTD (2024)';
    }

    presets.push({
      value: [periodStart, periodEnd],
      label,
    });
  }
  return presets;
};

interface ReportPeriodPresetsParams {
  reportPeriod: number;
  today: Dayjs;
  startLimit: Dayjs;
}

export function getReportPeriodPresets({
  reportPeriod,
  today,
  startLimit,
}: ReportPeriodPresetsParams): DatePreset[] {
  return Array.from({ length: reportPeriod + 1 }, (_, index) => {
    const month = today.subtract(index, 'month');

    if (month.isBefore(startLimit)) {
      return null;
    }

    return {
      label: month.format('MMM'),
      value: [month.startOf('month'), index === 0 ? today.endOf('day') : month.endOf('month')] as [
        Dayjs,
        Dayjs,
      ],
    };
  }).filter((preset): preset is DatePreset => preset !== null);
}
export type IsoDateString = string;

export default dayjs;
