import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  isFulfilled,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { isFetchBaseQueryError } from 'src/utils/auth-error-handler';
import { signUpApi } from 'src/store/signup/actions';
import { setAccessToken } from 'src/store/shared/rtkAuthApi/actions';
import { startListening } from 'src/store/middleware/listenerMiddleware';
import { skyRouter } from 'src/sky-router';
import type { ExtraArgument } from 'src/store';
import { resetStore } from 'src/store/actions';

export interface SignupState {
  email: string;
  fullName: string;
  company: string;
  referredBy: string;
  error: {
    title: string;
    description?: string;
  } | null;
  accessToken?: string;
  refreshToken?: string;
  confirmationResend?: 'resend' | 'no';
  code2FA?: string;
  isLoading?: boolean;
  isRecovery?: boolean;
}

const initialState: SignupState = {
  email: '',
  fullName: '',
  company: '',
  referredBy: '',
  error: null,
  isLoading: false,
  isRecovery: false,
};

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setEmail(state, action: PayloadAction<string>) {
      console.log('signup/setEmail:', action.payload);
      state.email = action.payload;
    },
    setReferredBy(state, action: PayloadAction<string>) {
      console.log('signup/setReferredBy:', action.payload);
      state.referredBy = action.payload;
    },
    setIsRecovery(state, action: PayloadAction<boolean>) {
      console.log('signup/setIsRecovery', action);
      state.isRecovery = action.payload;
    },
    setSignout(_, action) {
      console.log('signup/setSignout', action);
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setAccessToken, (state, action) => {
      console.log(setAccessToken.name, action.payload);
      state.accessToken = action.payload.accessToken;
    });
    builder.addMatcher(signUpApi.endpoints.postSignUpData.matchFulfilled, (state, action) => {
      console.log('signup/postSignUpData/fulfilled:', action.payload);
      state.accessToken = action.payload.accessToken;
    });
    builder.addMatcher(signUpApi.endpoints.postRefreshToken.matchFulfilled, (state, action) => {
      console.log('signup/postRefreshToken/fulfilled:', action.payload);
      state.accessToken = action.payload.accessToken;
    });
    builder.addMatcher(signUpApi.endpoints.postVerify2FA.matchFulfilled, (state, action) => {
      console.log('signup/postVerify2FA/fulfilled:');
      state.accessToken = action.payload.accessToken;
      state.isRecovery = false;
    });
    builder.addMatcher(signUpApi.endpoints.postLogout.matchFulfilled, (state) => {
      console.log('signup/postLogout/fulfilled:');
      state.accessToken = undefined;
    });
    builder.addMatcher(signUpApi.endpoints.postLogin.matchFulfilled, (state, action) => {
      console.log('signup/postLogin/fulfilled:');
      state.accessToken = action.payload.accessToken;
    });

    builder.addMatcher(signUpApi.endpoints.postAuthenticate2FA.matchFulfilled, (state, action) => {
      console.log('signup/postAuthenticate2FA/fulfilled:');
      state.accessToken = action.payload.accessToken;
    });
    builder.addMatcher(signUpApi.endpoints.postReset2FA.matchFulfilled, (state, action) => {
      console.log('signup/postReset2FA/fulfilled:');
      state.accessToken = action.payload.accessToken;
    });
    builder.addMatcher(signUpApi.endpoints.postRecover.matchFulfilled, (state, action) => {
      console.log('signup/postRecover/fulfilled:', action);
      state.accessToken = action.payload.accessToken;
      state.isRecovery = true;
    });
  },
});

// Refresh accessToken after the email confirmation request
startListening({
  matcher: isAnyOf(
    signUpApi.endpoints.postConfirmEmail.matchFulfilled,
    signUpApi.endpoints.postConfirmEmail.matchRejected,
  ),
  effect: async (action, listenerApi) => {
    if (isFulfilled(action)) {
      await listenerApi.dispatch(signUpApi.endpoints.postRefreshToken.initiate()).unwrap();
    }
    if (isRejectedWithValue(action) && isFetchBaseQueryError(action.payload)) {
      const data = action.payload;
      if ('message' in data) {
        if (data.message === 'Email already confirmed') {
          await listenerApi.dispatch(signUpApi.endpoints.postRefreshToken.initiate()).unwrap();
        }
      }
    }
  },
});

export const signOut = createAsyncThunk<unknown, never, { extra: ExtraArgument }>(
  'auth/signOut',
  async (_, thunkApi) => {
    await thunkApi.extra.persistor?.purge();
    await thunkApi.extra.persistor?.flush();
    thunkApi.dispatch(resetStore());
    thunkApi.dispatch(signupSlice.actions.setSignout(null));
    await skyRouter.router?.navigate('/signin');
  },
);

export const signupActions = {
  ...signupSlice.actions,
  signOut,
};
export default signupSlice.reducer;
