import { createSlice } from '@reduxjs/toolkit';

import isFunction from 'lodash/isFunction';

import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import { prepareAccounts } from 'src/store/utils/accounts';
import type { JsonConnection } from 'src/store/types';

import type { SliceFactoryProps } from 'src/store/shared/types';
import type {
  AccountCommands,
  AccountsState,
  JsonAccountDisableSuccess,
  JsonAccountEnableSuccess,
  JsonAccountsSuccess,
} from './types';
import { AccountStatus } from './types';
import { disableAccount, enableAccount } from './actions';
import { resetStore } from 'src/store/actions';

export function initAccountsState(opts?: Partial<AccountsState>) {
  const initialState: AccountsState = {
    requestStatus: null,
    error: null,

    stats: [],
    list: [],
    brokenAccounts: [],

    resUpdateAccount: null,
  };

  return { ...initialState, ...opts };
}

function helperUpdateAccountState(updated: Partial<JsonConnection>) {
  return (account: JsonConnection) => {
    if (account.name === updated.name) {
      return {
        ...account,
        ...updated,
      };
    }
    return account;
  };
}

export function initAccountsSlice({
  prefix = 'shared',
  sliceName = 'accounts',
  initialState = initAccountsState(),
  extraReducers,
  commands,
}: SliceFactoryProps<AccountsState, typeof AccountCommands>) {
  if (!commands) {
    throw new Error('Commands are required for accounts slice factory');
  }

  const allowedCommands = Object.values(commands);

  const accountsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      // listen
      if (isFunction(extraReducers)) {
        extraReducers(builder);
      }

      // enableAccount

      builder.addCase(enableAccount, (state, action) => {
        console.log(`${enableAccount.toString()}:`, action);
        state.resUpdateAccount = {
          id: action.payload.accountID,
          status: 'pending',
        };
      });

      // disableAccount

      builder.addCase(disableAccount, (state, action) => {
        console.log(`${disableAccount.toString()}:`, action.payload);
        state.resUpdateAccount = {
          id: action.payload.accountID,
          status: 'pending',
        };
      });

      // on WS message

      builder.addCase(actionOnMessage, (state, action) => {
        const { json, skipProcessing, error, errorCommand } = parseResponse(
          action.payload,
          allowedCommands,
        );

        if (skipProcessing || !json) return;

        console.debug(
          `${prefix}:shared/accounts/processing ${actionOnMessage.toString()}`,
          action,
          allowedCommands,
        );

        const { command, okCommand } = json;

        if (error) {
          state.error = error;

          switch (errorCommand) {
            case commands.EnableAccount:
              state.resUpdateAccount = null;
              break;

            default:
          }

          return;
        }

        if (command === commands.Accounts) {
          const { accounts, errors }: JsonAccountsSuccess = json;
          state.stats = accounts;

          // convert JsonAccountStats to JsonConnection
          state.list = prepareAccounts(accounts);
          state.brokenAccounts = errors;
          // state.isExchangeLoading = false;

          state.requestStatus = 'success';
          return;
        }

        if (command === 'ok' && okCommand === commands.EnableAccount) {
          const { result }: JsonAccountEnableSuccess = json;

          try {
            const { name, status, message, label, proxy } = JSON.parse(result) as JsonConnection;

            if (state.resUpdateAccount) {
              state.resUpdateAccount.status = 'success';
            }

            if (state.brokenAccounts.some((account) => account.name === name)) {
              state.brokenAccounts = state.brokenAccounts.map(
                helperUpdateAccountState({ name, status, message, label, proxy }),
              );
            }
          } catch (err) {
            console.error(
              'Failed to parse response data for account status update request',
              result,
              err,
            );
          }
          console.debug(`${okCommand as string} accountID:`, json);
          return;
        }

        if (command === 'ok' && okCommand === commands.DisableAccount) {
          const { id }: JsonAccountDisableSuccess = json;

          state.resUpdateAccount!.status = 'success';

          if (state.brokenAccounts.some((account) => account.name === id)) {
            state.brokenAccounts = state.brokenAccounts.map(
              helperUpdateAccountState({ name: id, status: AccountStatus.Disabled }),
            );
          }
          console.debug(`${okCommand as string} accountID:`, json);
        }
      });

      builder.addCase(resetStore, () => {
        return initialState;
      });
    },
  });

  const actions = {
    ...accountsSlice.actions,
    enableAccount,
    disableAccount,
  };

  return {
    actions,
    reducer: accountsSlice.reducer,
  };
}
