import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// import dayjs from 'src/utils/formatting/dates';

import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';

import { listen as listenRetainers } from 'src/store/retainers/actions';
import { listen as listenLoans } from 'src/store/loans/actions';
import type {
  ManualTransactionFormValues,
  TransactionsState,
  JsonTransactionsListenResponse,
  TransactionsViewFilterType,
  TransactionsSortByOrder,
  ManualTransactionMetaData,
} from './types';
import { TransactionCommands } from './types';
import {
  getTransactions,
  updateTransaction,
  addManualTransaction,
  removeManualTransaction,
} from './actions';

const allowedCommands = Object.values(TransactionCommands);

export const initialState: TransactionsState = {
  requestStatus: null,
  error: null,

  list: [],

  // set initial balance
  isManualTransactionModalOpen: false,
  formManualTransaction: undefined,
  metaManualTransaction: undefined,
  resManualTransaction: null,

  closeAfterSubmit: true,

  viewSortByOrder: 'name:desc',
  viewFilterByType: 'noFilter',
};

const TransactionsSlice = createSlice({
  name: 'manualTransactions',
  initialState,
  reducers: {
    resetTransactions: (state) => {
      state.requestStatus = null;
      state.error = null;
      state.list = [];
    },

    // setInitialState(state, action: PayloadAction<Partial<TransactionsState>>) {
    //   state.startDate = action.payload.startDate ?? initialState.startDate;
    //   state.endDate = action.payload.endDate ?? initialState.endDate;
    // },

    // setComparisonRange(state, action: PayloadAction<{ startDate?: string; endDate?: string }>) {
    //   state.startDate = action.payload.startDate;
    //   state.endDate = action.payload.endDate;
    // },

    openManualTransactionModal(state, action: PayloadAction<ManualTransactionFormValues>) {
      state.isManualTransactionModalOpen = true;
      const { meta, ...rest } = action.payload;
      state.formManualTransaction = rest;
      state.metaManualTransaction = meta as ManualTransactionMetaData;
      state.resManualTransaction = null;
    },

    closeManualTransactionModal(state, action: PayloadAction<object>) {
      console.debug('closeManualTransactionModal action:', action);
      state.isManualTransactionModalOpen = false;
      state.formManualTransaction = undefined;
    },

    setViewSortOrder(state, action: PayloadAction<TransactionsSortByOrder>) {
      state.viewSortByOrder = action.payload;
    },

    setViewFilterType(state, action: PayloadAction<TransactionsViewFilterType>) {
      state.viewFilterByType = action.payload;
    },
  },
  extraReducers: (builder) => {
    // listen
    builder.addCase(listenRetainers.pending, (state, action) => {
      const { isInitCall, section } = action.meta.arg;
      if (isInitCall) {
        if (section === 'transactions') {
          state.requestStatus = 'pending';
        } else {
          state.requestStatus = null;
        }
      }
    });

    builder.addCase(listenLoans.pending, (state, action) => {
      const { isInitCall, section } = action.meta.arg;
      if (isInitCall) {
        if (section === 'transactions') {
          state.requestStatus = 'pending';
        } else {
          state.requestStatus = null;
        }
      }
    });

    // builder.addCase(getManualTransactions, (state /* , action */) => {
    //   // const { section } = action.meta.arg
    //   state.requestStatus = 'pending';
    // });

    builder.addCase(addManualTransaction, (state, action) => {
      console.log(`${addManualTransaction.toString()}:`, action.payload);
      // const { section } = action.meta.arg
      state.resManualTransaction = 'pending';
      state.closeAfterSubmit = action.payload.closeAfterSubmit ?? false;
    });

    builder.addCase(updateTransaction, (state, action) => {
      console.log(`${updateTransaction.toString()}:`, action.payload);
      state.list = state.list.map((entry) => {
        const { id, comment } = (action.meta as { arg: { id: string; comment: string } }).arg;
        if (entry.id === id) {
          return {
            ...entry,
            comment: comment,
          };
        }

        return entry;
      });
    });

    // on WS message

    builder.addCase(actionOnMessage, (state, action: PayloadAction<{ message: string }>) => {
      const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      console.debug(
        `shared/transactions/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      const { command, okCommand } = json;

      if (error) {
        state.error = error;
        state.requestStatus = 'error';
        return;
      }

      // if (command === TransactionCommands.ManualTransactions) {
      //   const { list }: JsonManualTransactionsSuccess = json;

      //   if (list) {
      //     state.list = list;
      //     state.requestStatus = 'success';
      //   }
      //   return;
      // }

      if (command === 'ok') {
        switch (okCommand) {
          // case 'Projects':
          //   state.list = json.list;
          //   break;
          case TransactionCommands.AddTransaction:
            state.resManualTransaction = 'success';
            state.isManualTransactionModalOpen = !state.closeAfterSubmit;
            break;

          case TransactionCommands.RemoveTransaction:
            state.resManualTransaction = 'success';
            break;

          default:
        }
      } else {
        switch (command) {
          case TransactionCommands.Transactions:
          case TransactionCommands.GetTransactions: {
            const { list } = json as JsonTransactionsListenResponse;
            state.requestStatus = 'success';
            state.list = list;
            break;
          }
          default:
        }
      }
    });
  },
});

export const transactionsActions = {
  ...TransactionsSlice.actions,
  getTransactions,
  updateTransaction,
  addManualTransaction,
  removeManualTransaction,
};
// export const { toggleSidebar } = TransactionsSlice.actions

export default TransactionsSlice.reducer;
