import { createAction } from '@reduxjs/toolkit';
import type { Reducer } from '@reduxjs/toolkit';

export const resetStore = createAction('app/resetStore');

export const withResetState = <T>(reducer: Reducer<T>, initialState: T): Reducer<T> => {
  return (state, action) => {
    if (action.type === resetStore.type) {
      return initialState;
    }
    return reducer(state, action);
  };
};
