import { createWsAction } from 'src/store/utils';
import type { AddBalancePayload } from 'src/store/shared/balances/types';
import { BalanceCommands } from 'src/store/shared/balances/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'src/store';
import { selectLoanBalancesExtended } from 'src/store/loans/balances/selectors';
import { selectColdWallets as selectLoanColdWallets } from 'src/store/loans/cold-wallets/selectors';
import { selectColdWallets as selectProjectColdWallets } from 'src/store/retainers/cold-wallets/selectors';
import { walletsApi } from 'src/store/cold-wallets/actions';
import each from 'lodash/each';

const addBalanceWs = createWsAction<AddBalancePayload, AddBalancePayload>(
  'balances/addBalanceWs',
  (data) => ({
    command: BalanceCommands.AddBalance,
    ...data,
  }),
);

export const addBalance = createAsyncThunk(
  'balances/addBalance',
  (data: AddBalancePayload, { getState, dispatch }) => {
    const accounts = selectLoanBalancesExtended(getState() as RootState);
    const account = accounts.find((acc) => acc.accountID === data.accountID);
    const balances = account?.assets ?? [];

    each(balances, ({ token, volume, volumeUSD }) => {
      const updated = data.balances.find((b) => b.token === token);
      if (!updated) {
        data.balances.push({
          token,
          value: volume,
          usd: volumeUSD,
        });
      }
    });

    return dispatch(addBalanceWs(data));
  },
);

export const createSnapshots = createAsyncThunk(
  'balances/createSnapshots',
  (data: { loanID: number } | { projectID: number }, { dispatch, getState }) => {
    let selectColdWallets;
    if ((data as { loanID: number }).loanID) {
      selectColdWallets = selectLoanColdWallets;
      dispatch(createBalancesSnapshot({ loanID: (data as { loanID: number }).loanID }));
    }
    if ((data as { projectID: number }).projectID) {
      selectColdWallets = selectProjectColdWallets;
      dispatch(createBalancesSnapshot({ projectID: (data as { projectID: number }).projectID }));
    }
    const coldWallets = selectColdWallets?.(getState() as RootState) ?? [];
    const walletIds = coldWallets.map(({ accountID }) => Number(accountID));
    return dispatch(walletsApi.endpoints.postAddWalletSnapshot.initiate({ walletIds }));
  },
);

export const createBalancesSnapshot = createWsAction<
  { loanID: number } | { projectID: number },
  { loanID: number } | { projectID: number }
>('balances/createSnapshotWs', (data) => ({
  command: BalanceCommands.AddBalance,
  ...data,
}));
