import type { GenericError } from 'src/store/utils/errors';
import type { LiquidityMap } from 'src/store/shared/kpi/types';
import type { JsonLoanExchangeExtended } from 'src/store/loans/exchanges/types';
import type { RequestStatus } from 'src/store/types';
import type { ActualDepth, DepthDataMap } from 'src/store/loans/types';
import { LoanCommands } from 'src/store/loans/types';

export enum LoanLiquidityCommands {
  LoanLiquidity = LoanCommands.LoanLiquidity,
}

export interface JsonLoanLiquiditySuccess {
  command: LoanLiquidityCommands.LoanLiquidity;
  id: number;
  liquidity: JsonLoanLiquidity[];
  details: JsonLoanDetails[];
}

export interface JsonLoanDetails {
  tokens: string;
  name: string;
  company: string;
  started: string;
  ended: string;
  targetDelta: number;
  strikePrice: number;
  amounts: {
    baseAmount: number;
    quoteAmount: number;
  };
  paybackAmounts: {
    baseAmount: number;
    quoteAmount: number;
    residualAmount: number;
  };
  exchanges: number[];
  pairs: string[];
  funds: {
    Amount: number;
    Token: string;
    Author: string;
  };
}

export interface JsonLoanLiquidity {
  ExchangeID: number;
  Pair: string;
  AccountID: string;
  AccountName: string;
  Liquidity: LiquidityMap;
  ActualLiquidity: Record<ActualDepth, number>;
}

export type LoanLiquidityCache = Record<string, JsonLoanLiquidity[]>;

export type LoanLiquidityViewFilters = 'no' | 'supportedPairs';
export type LoanLiquidityAggregatedValuesFilter = 'fromServer' | 'fromBots';

export interface LoanLiquidityState {
  requestStatus: RequestStatus;
  error: null | GenericError;
  list: JsonLoanLiquidity[] | null;
  listCache: LoanLiquidityCache;
  viewFilter: LoanLiquidityViewFilters;
  aggregatedValuesFilter: LoanLiquidityAggregatedValuesFilter;
}

export interface AccountLiquidity {
  accountId: string;
  accountName: string;
  volumeLocal: number;
  volumeUTC: number;
  accountVolumeByDateLocal: [string, number][];
  accountVolumeByDateUTC: [string, number][];
  avgAccountLiquidityPlus1: number;
  avgAccountLiquidityPlus2: number;
  avgAccountLiquidityMinus1: number;
  avgAccountLiquidityMinus2: number;
  avgAccountLiquidityPlus1ByDate: [string, number][];
  avgAccountLiquidityPlus2ByDate: [string, number][];
  avgAccountLiquidityMinus1ByDate: [string, number][];
  avgAccountLiquidityMinus2ByDate: [string, number][];
  currentAccountLiquidityPlus1: number;
  currentAccountLiquidityPlus2: number;
  currentAccountLiquidityMinus1: number;
  currentAccountLiquidityMinus2: number;
  actualAccountLiquidityPlus1: number;
  actualAccountLiquidityPlus2: number;
  actualAccountLiquidityMinus1: number;
  actualAccountLiquidityMinus2: number;
}

export type AccountLiquidityTree = AccountLiquidity &
  Pick<
    JsonLoanExchangeExtended,
    | 'avgMarketLiquidityMinus1'
    | 'avgMarketLiquidityPlus1'
    | 'avgMarketLiquidityMinus2'
    | 'avgMarketLiquidityPlus2'
    | 'kpi'
    | 'exchange'
  > & {
    key: string;
    depthData: DepthDataMap;
  };

export interface JsonLoanLiquidityExtended extends JsonLoanExchangeExtended {
  liquidityByAccount: AccountLiquidity[];
  botsVolumeLocal: number;
  botsVolumeUTC: number;
  aggregatedValuesFilter: LoanLiquidityAggregatedValuesFilter;
  avgBotsLiquidityPlus1: number;
  avgBotsLiquidityPlus2: number;
  avgBotsLiquidityMinus1: number;
  avgBotsLiquidityMinus2: number;
  avgBotsLiquidityPlus1ByDate: [string, number][];
  avgBotsLiquidityPlus2ByDate: [string, number][];
  avgBotsLiquidityMinus1ByDate: [string, number][];
  avgBotsLiquidityMinus2ByDate: [string, number][];
  currentBotsLiquidityPlus1: number;
  currentBotsLiquidityPlus2: number;
  currentBotsLiquidityMinus1: number;
  currentBotsLiquidityMinus2: number;
  actualBotsLiquidityPlus1: number;
  actualBotsLiquidityPlus2: number;
  actualBotsLiquidityMinus1: number;
  actualBotsLiquidityMinus2: number;
  marketDepthData: DepthDataMap;
}

export interface JsonLoanLiquidityExtendedTree extends JsonLoanLiquidityExtended {
  key: string;
  children: (AccountLiquidityTree & { key: string })[];
}
