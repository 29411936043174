import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'qs';
import type { RootState } from 'src/store';
import { connect, disconnect, send } from '../middleware/redux-websocket';
import { prefix } from './middleware';
import { TX_WS_URL, TX_WS_SS_KEY, TX_WS_QS_KEY, ALLOW_WS_OVERRIDE } from 'src/constants';
import isString from 'lodash/isString';

export const actionTxConnect = createAction<object>(`${prefix}::CONNECT`);
export const actionTxOnOpen = createAction<object>(`${prefix}::OPEN`);
export const actionTxDisconnect = createAction<object>(`${prefix}::DISCONNECT`);
export const actionTxOnClose = createAction<object>(`${prefix}::CLOSE`);
export const actionTxOnClosed = createAction<object>(`${prefix}::CLOSED`);
export const actionTxSend = createAction<object>(`${prefix}::SEND`);
export const actionTxOnMessage = createAction<{ message: string } & Record<string, any>>( // eslint-disable-line @typescript-eslint/no-explicit-any
  `${prefix}::MESSAGE`,
);
export const actionTxOnBroken = createAction<object>(`${prefix}::BROKEN`);
export const actionTxOnReconnectBegin = createAction<object>(`${prefix}::BEGIN_RECONNECT`);
export const actionTxOnReconnectAttempt = createAction<object>(`${prefix}::RECONNECT_ATTEMPT`);
export const actionTxOnReconnected = createAction<object>(`${prefix}::RECONNECTED`);
// TODO: add error typing
export const actionTxOnError = createAction<object>(`${prefix}::ERROR`);

export const wsTxConnect = () => {
  const query = qs.parse(document.location.search, { ignoreQueryPrefix: true });
  const qsVal = isString(query[TX_WS_QS_KEY]) ? (query[TX_WS_QS_KEY] as string) : undefined;

  if (qsVal && ALLOW_WS_OVERRIDE === '1') {
    if (qsVal !== '0') {
      sessionStorage.setItem(TX_WS_SS_KEY, qsVal);
      console.log(`Tx WS URL has been replaced with query param: ${qsVal}`);
      return connect(qsVal, prefix);
    }
    sessionStorage.removeItem(TX_WS_SS_KEY);
  }

  const storedWs = sessionStorage.getItem(TX_WS_SS_KEY);
  if (storedWs && ALLOW_WS_OVERRIDE === '1') {
    return connect(storedWs, prefix);
  }

  return connect(TX_WS_URL, prefix);
};

export const wsTxDisconnect = () => disconnect(prefix);
// TODO: add message typing
export const wsTxSend = (message: { command: string }) => send(message, prefix);

export const subscribeWsTxData = createAsyncThunk('ws/tx-connect', (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const wsStatus = state.wsTx.status;

  if (wsStatus === 'idle') {
    thunkAPI.dispatch(wsTxConnect());
  }
});
