import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface RtkQueryErrorState {
  error: null | {
    title: string;
    description?: string;
  };
  status: string | null;
  message: string | null;
}

export const initialState: RtkQueryErrorState = {
  error: null,
  status: null,
  message: null,
};

const rtkQueryErrorSlice = createSlice({
  name: 'rtkQueryError',
  initialState,
  reducers: {
    setError(
      state,
      action: PayloadAction<{
        data?: { message: string; error: string };
        status: number | string | null;
      }>,
    ) {
      console.log('~ rtkQueryError/setError', action);
      const title = action.payload.data?.message ?? action.payload.data?.error;
      state.error = title ? { title } : null;
      state.status = action.payload.status === null ? null : String(action.payload.status);
    },
  },
});

export const rtkQueryErrorActions = rtkQueryErrorSlice.actions;
export default rtkQueryErrorSlice.reducer;
