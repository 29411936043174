import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { GenericError } from 'src/store/utils/errors';
import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import type { JsonConnection, RequestStatus } from 'src/store/types';

import some from 'lodash/some';

// import { listen } from '../actions';
import type { JsonProjectResponse } from './types';
import { ProjectCommands } from './types';
import { EMPTY_ARRAY } from 'src/constants';
import { startListening } from 'src/store/middleware/listenerMiddleware';
import { selectTargetProject } from 'src/store/retainers/selectors';
import { ApplicationDomain, skyRouter } from 'src/sky-router';
import { retainersActions } from 'src/store/retainers/slice';
import { getDefaultClientProjectFromList } from 'src/store/retainers/projects/helpers';

export interface JsonProjectExchange {
  id: number;
  bots: number;
}

export interface JsonProject {
  id: number;
  name: string;
  tabs: JsonProjectExchange[];
}

export interface RetainersProjectsState {
  requestStatus: RequestStatus;
  error: null | GenericError;

  list: JsonProject[];
  companies: string[];
  sortKey: string;
  searchInput: string;
}

const allowedCommands = Object.values(ProjectCommands);

export const initialState: RetainersProjectsState = {
  requestStatus: null,
  error: null,

  list: [],
  companies: EMPTY_ARRAY,
  // search and sort

  searchInput: '',
  sortKey: 'name_asc',
};

const retainersProjectsSlice = createSlice({
  name: 'projects', // retainers.projects
  initialState,
  reducers: {
    setSearchInput(state, action: PayloadAction<string>) {
      state.searchInput = action.payload;
    },
    setSortKey(state, action: PayloadAction<string>) {
      state.sortKey = action.payload;
    },
  },
  extraReducers: (builder) => {
    // on WS message

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error } = parseResponse<JsonProjectResponse>(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;

      console.debug(
        `retainers/projects/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      const { command } = json;

      if (error) {
        state.error = error;
        return;
      }

      if (command === ProjectCommands.Projects) {
        state.list = json.list;
        state.companies = json.companies || EMPTY_ARRAY;
        state.requestStatus = 'success';
        return;
      }

      // if (command === 'ok') {
      //   switch (okCommand) {
      //     // case 'Projects':
      //     //   state.list = json.list;
      //     //   break;
      //
      //     default:
      //   }
      // }
    });
  },
});

export const retainersProjectsActions = { ...retainersProjectsSlice.actions };
// export const { toggleSidebar } = retainersProjectsSlice.actions;

export default retainersProjectsSlice.reducer;

startListening({
  actionCreator: actionOnMessage,
  effect: (action, listenerApi) => {
    const json = JSON.parse((action.payload as JsonConnection).message) as JsonProjectResponse;
    if (json.command === ProjectCommands.Projects) {
      const { list } = json;
      const currentTargetId = selectTargetProject(listenerApi.getState());
      if (!currentTargetId && list?.length) {
        const defaultProject = getDefaultClientProjectFromList(list);
        const [, , , urlProjectId] = skyRouter.getPathname().split('/');
        const projectId = urlProjectId ? urlProjectId : String(defaultProject?.id ?? '');
        const isExist = some(list, { id: Number(projectId) });
        const domain = skyRouter.getDomain();
        if (!isExist && domain === ApplicationDomain.Retainers) {
          void skyRouter.router?.navigate('/retainers/not-found');
          return;
        }
        listenerApi.dispatch(retainersActions.setTargetProject({ projectId }));
      }
    }
  },
});
