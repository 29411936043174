import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import { listen as listenLoans } from 'src/store/loans/actions';
import { LoansSummaryCommands } from './types';
import type { LoansSummaryState, JsonLoansSummarySuccess } from './types';
import type { LoansSummaryTimeFrame } from 'src/components/Loans/Summary/types';

const allowedCommands = Object.values(LoansSummaryCommands);

export const initialState: LoansSummaryState = {
  data: [],
  status: 'pending',
  error: null,
  searchValue: '',
  sortKey: 'created_asc',
  timeFrame: '24h',
};

const loansSummarySlice = createSlice({
  name: 'loansSummary',
  initialState,
  reducers: {
    setSearchValue(state, action: PayloadAction<string>) {
      state.searchValue = action.payload;
    },
    setSortKey(state, action: PayloadAction<string>) {
      state.sortKey = action.payload;
    },
    setTimeFrame(state, action: PayloadAction<LoansSummaryTimeFrame>) {
      state.timeFrame = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listenLoans.pending, (state, action) => {
      const { isInitCall, section } = action.meta.arg;
      if (isInitCall && section === 'summary') {
        state.status = 'pending';
      }
    });

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error, errorCommand } = parseResponse(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;

      console.debug(
        `loans/funds/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      const { command } = json;

      if (error) {
        state.error = error;

        switch (errorCommand) {
          default:
        }

        return;
      }

      if (command === LoansSummaryCommands.LoansSummary) {
        const { loans } = json as JsonLoansSummarySuccess;
        state.status = 'success';
        state.data = loans;
      }
    });
  },
});

export const loanTradingViewActions = { ...loansSummarySlice.actions };

export default loansSummarySlice.reducer;
