import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import dayjs from 'src/utils/formatting/dates';

import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';

import { startListening } from 'src/store/middleware/listenerMiddleware';
import type { RootState } from 'src/store';
import {
  selectComparisonEndDate,
  selectComparisonStartDate,
} from 'src/store/shared/balance-history/selectors';
import type {
  BalanceHistoryState,
  JsonBalanceHistorySuccess,
  JsonPriceHistorySuccess,
  PayloadGetBalanceHistory,
  SetObjInitialBalanceFormValues,
} from './types';
import { BalanceHistoryCommands } from './types';
import { getBalanceHistoryWs, setInitialBalance } from './actions';
import { getComparisonTimeOptionValue } from './helpers';

const allowedCommands = Object.values(BalanceHistoryCommands);

// If the start of the week is today, set the comparison start to the start of the previous week
const initialStartDate = dayjs().startOf('isoWeek').isSameOrAfter(dayjs(), 'date')
  ? dayjs().startOf('isoWeek').add(-1, 'week').format('YYYY-MM-DD HH:mm')
  : dayjs().startOf('isoWeek').format('YYYY-MM-DD HH:mm');
const initialEndDate = dayjs().endOf('day').format('YYYY-MM-DD HH:mm');

export const initialState: BalanceHistoryState = {
  requestStatus: null,
  error: null,

  list: [],
  priceHistory: {},
  prices: {},

  startDate: initialStartDate,
  startTime: '00:00',
  endDate: initialEndDate,
  endTime: 'current',

  // set initial balance
  isInitialBalanceModalOpen: false,
  formSetInitialBalance: undefined,
  resSetInitialBalance: null,
};

const BalanceHistorySlice = createSlice({
  name: 'balanceHistory', // retainers.balanceHistory
  initialState,
  reducers: {
    setInitialState(state, action: PayloadAction<Partial<BalanceHistoryState>>) {
      if (action.payload.startDate && dayjs.utc().isSameOrAfter(action.payload.startDate)) {
        state.startDate = action.payload.startDate ?? initialState.startDate;
      }
      if (action.payload.endDate && dayjs.utc().isSameOrAfter(action.payload.endDate)) {
        state.endDate = action.payload.endDate ?? initialState.endDate;
      }
    },

    setComparisonRange(state, action: PayloadAction<{ startDate?: string; endDate?: string }>) {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;

      if (action.payload.startDate) {
        state.startTime = getComparisonTimeOptionValue(action.payload.startDate);
      } else {
        state.startTime = '00:00';
      }

      if (action.payload.endDate) {
        state.endTime = getComparisonTimeOptionValue(action.payload.endDate);
      } else {
        state.endTime = 'current';
      }
    },

    openSetInitialBalanceModal(state, action: PayloadAction<SetObjInitialBalanceFormValues>) {
      state.isInitialBalanceModalOpen = true;
      state.formSetInitialBalance = action.payload;
      state.resSetInitialBalance = null;
    },

    closeSetInitialBalanceModal(state, action: PayloadAction<object>) {
      console.debug('closeSetInitialBalanceModal action:', action);
      state.isInitialBalanceModalOpen = false;
      state.formSetInitialBalance = undefined;
    },
  },
  extraReducers: (builder) => {
    // listen

    builder.addCase(getBalanceHistoryWs, (state /* , action */) => {
      // const { section } = action.meta.arg
      state.requestStatus = 'pending';
    });

    builder.addCase(setInitialBalance, (state /* , action */) => {
      // const { section } = action.meta.arg
      state.resSetInitialBalance = 'pending';
    });

    // on WS message

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      console.debug(
        `balance-history/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      const { command, okCommand } = json;

      if (error) {
        state.error = error;
        return;
      }

      if (command === BalanceHistoryCommands.PriceHistory) {
        const { list } = json as JsonPriceHistorySuccess;
        if (list) {
          state.priceHistory = list;
        }
        return;
      }

      if (command === BalanceHistoryCommands.Prices) {
        const { list } = json as JsonPriceHistorySuccess;
        if (list) {
          state.prices = list;
        }
        return;
      }

      if (command === BalanceHistoryCommands.BalanceHistory) {
        const { list } = json as JsonBalanceHistorySuccess;

        if (list) {
          state.list = list;
          state.requestStatus = 'success';
        }
        return;
      }

      if (command === 'ok') {
        switch (okCommand) {
          // case 'Projects':
          //   state.list = json.list;
          //   break;
          case BalanceHistoryCommands.SetInitialBalance:
            state.resSetInitialBalance = 'success';
            state.isInitialBalanceModalOpen = false;
            break;

          default:
        }
      }
    });
  },
});

// Action is set here, but not in the actions module to avoid circular dependencies
const getBalanceHistory = createAsyncThunk(
  'retainers/GetBalanceHistory',
  (data: PayloadGetBalanceHistory, { getState, dispatch }) => {
    const state = getState() as RootState;
    const storeEndDate = selectComparisonEndDate(state);
    const storeStartDate = selectComparisonStartDate(state);

    const { startDate, endDate } = data;

    // Update comparison range if it has changed
    if (startDate && endDate && (startDate !== storeStartDate || endDate !== storeEndDate)) {
      dispatch(
        BalanceHistorySlice.actions.setComparisonRange({
          startDate,
          endDate,
        }),
      );
    }

    // If only end date time has changed, do not request history data as we already have the latest snapshot loaded.
    if (
      dayjs(storeEndDate).startOf('day').isSame(dayjs(endDate).startOf('day')) &&
      startDate === storeStartDate
    ) {
      return;
    }

    const payload = {
      ...(data.loanID && { loanID: data.loanID }),
      ...(data.projectID && { projectID: data.projectID }),
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      startDate: startDate || storeStartDate || initialStartDate,
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      endDate: endDate || storeEndDate || initialEndDate,
    };
    dispatch(getBalanceHistoryWs(payload));
  },
);

export const balanceHistoryActions = {
  ...BalanceHistorySlice.actions,
  getBalanceHistory,
  setInitialBalance,
};
// export const { toggleSidebar } = BalanceHistorySlice.actions

export default BalanceHistorySlice.reducer;

// on set initial balance submit
// it should request history list with the same params
startListening({
  actionCreator: actionOnMessage,
  effect: async (action, listenerApi) => {
    const { json, skipProcessing } = parseResponse(action.payload, allowedCommands);

    if (skipProcessing || !json) return;

    const { command, okCommand } = json;

    if (command === 'ok' && okCommand === BalanceHistoryCommands.SetInitialBalance) {
      const state = listenerApi.getState();
      const { balanceHistory, loans } = state;

      if (!loans.index.targetLoan) return;

      await listenerApi.dispatch(
        getBalanceHistory({
          loanID: +loans.index.targetLoan,
          startDate: balanceHistory.startDate ?? '',
          endDate: balanceHistory.endDate ?? '',
        }),
      );
    }
  },
});
