import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { createWsAction, parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import type { SliceFactoryProps } from 'src/store/shared/types';
import isFunction from 'lodash/isFunction';
import type {
  AccountsThresholdState,
  DeleteAccountThresholdPayload,
  JsonAccountThreshold,
  JsonLoanAccountThresholdsSuccess,
} from './types';
import type { ProjectAccountThresholdCommands } from 'src/store/retainers/threshold/slice';
import type { LoanAccountThresholdCommands } from 'src/store/loans/threshold/slice';
import { resetStore } from 'src/store/actions';

function initThresholdStateFactory(opts?: Partial<AccountsThresholdState>) {
  const initialState: AccountsThresholdState = {
    thresholdByAccountId: {},
    requestStatus: null,
    updateStatus: null,
    error: null,
    updateData: null,
  };
  return { ...initialState, ...opts };
}

export function initThresholdSlice({
  prefix = 'shared',
  sliceName = 'threshold',
  initialState = initThresholdStateFactory(),
  extraReducers,
  commands,
}: SliceFactoryProps<
  AccountsThresholdState,
  typeof ProjectAccountThresholdCommands | typeof LoanAccountThresholdCommands
>) {
  if (!commands) {
    throw new Error('Commands are required for threshold slice factory');
  }

  const allowedCommands = commands ? (Object.values(commands) as string[]) : [];

  const setAccountThreshold = createWsAction<JsonAccountThreshold, JsonAccountThreshold>(
    `${prefix}/setAccountThresholdWs`,
    (data: JsonAccountThreshold) => ({
      command: commands.UpsertAccountThreshold,
      ...data,
    }),
  );

  const deleteAccountThreshold = createWsAction<
    DeleteAccountThresholdPayload,
    DeleteAccountThresholdPayload
  >(`${prefix}/deleteAccountThresholdWs`, (data) => ({
    command: commands.DeleteAccountThreshold,
    ...data,
  }));

  const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      setThresholdUpdateData: (state, action: PayloadAction<JsonAccountThreshold>) => {
        state.updateData = action.payload;
      },
    },
    extraReducers: (builder) => {
      // listen
      if (isFunction(extraReducers)) {
        extraReducers(builder);
      }

      builder.addCase(actionOnMessage, (state, action) => {
        const { json, skipProcessing, error, errorCommand } = parseResponse(
          action.payload,
          allowedCommands,
        );

        if (skipProcessing || !json) return;

        console.debug(
          `${prefix}/${sliceName}/processing ${actionOnMessage.toString()}`,
          action,
          allowedCommands,
        );

        const { command, okCommand } = json;

        if (error) {
          state.error = error;
          switch (errorCommand) {
            case commands.UpsertAccountThreshold:
            case commands.DeleteAccountThreshold:
              state.updateStatus = 'error';
              break;
            default:
          }
        }

        if (command === 'ok') {
          switch (okCommand) {
            case commands.AccountThresholds:
              state.requestStatus = 'success';
              break;
            case commands.UpsertAccountThreshold: {
              if (state.updateData === null) {
                return;
              }
              const { accountID } = state.updateData;
              const existingThresholds = state.thresholdByAccountId?.[accountID] || [];
              state.thresholdByAccountId = {
                ...state.thresholdByAccountId,
                [accountID]: [...existingThresholds, state.updateData],
              };
              state.updateStatus = 'success';
              state.updateData = null;
              break;
            }
            case commands.DeleteAccountThreshold: {
              if (state.updateData === null) {
                return;
              }
              const { accountID, token } = state.updateData;
              const updatedThresholds = state.thresholdByAccountId?.[accountID]?.filter(
                (threshold) => threshold.token !== token,
              );
              state.thresholdByAccountId = {
                ...state.thresholdByAccountId,
                [accountID]: updatedThresholds,
              };

              state.updateStatus = 'success';
              state.updateData = null;
              break;
            }
            default:
          }
        }

        if (command === commands.AccountThresholds) {
          const { thresholds } = json as JsonLoanAccountThresholdsSuccess;
          state.thresholdByAccountId = thresholds.reduce(
            (acc, threshold) => {
              if (!acc[threshold.accountID]) {
                acc[threshold.accountID] = [];
              }
              acc[threshold.accountID].push(threshold);
              return acc;
            },
            {} as Record<string, JsonAccountThreshold[]>,
          );
          state.requestStatus = 'success';
        }
      });

      builder.addCase(setAccountThreshold, (state) => {
        state.updateStatus = 'pending';
      });

      builder.addCase(resetStore, () => {
        return initialState;
      });
    },
  });

  const actions = {
    ...slice.actions,
    setAccountThreshold,
    deleteAccountThreshold,
  };

  return {
    actions,
    reducer: slice.reducer,
  };
}
