import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { createApiKey, deleteApiKey, listen, rtkApiKey } from 'src/store/trading/apiKeys/actions';

import type { ApiKeyItem, TradingApiKeysState } from 'src/store/trading/apiKeys/types';

export interface JsonApiKeysResponse {
  list: ApiKeyItem[];
  command: 'APIKeys';
}

export const initialState: TradingApiKeysState = {
  activeKeys: [],
  selectedExchange: undefined,
  exchange: {},
};

const tradingApiKeysSlice = createSlice({
  name: 'tradingApiKeys',
  initialState,
  reducers: {
    onSelectedExchange: (state, action: PayloadAction<number>) => {
      state.selectedExchange = action.payload;
    },
    addApiKey: (state, action: PayloadAction<ApiKeyItem>) => {
      state.activeKeys.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    // listen

    // on WS message

    builder.addMatcher(rtkApiKey.endpoints.getExchange.matchFulfilled, (state, action) => {
      state.exchange = action.payload;
    });
  },
});

export const apiKeysAction = {
  ...tradingApiKeysSlice.actions,
  listen,
  createApiKey,
  deleteApiKey,
};

export default tradingApiKeysSlice.reducer;
